<template>
  <div id="mode_index">
    <el-switch @change="kaiqi_guanbi" v-model="value1" active-text="人工开启" inactive-text="人工关闭">
    </el-switch>
    <el-table
      @expand-change="zhankai"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column type="expand" cell-click="click">
        <template slot-scope="">
          <el-form label-position="left" inline class="demo-table-expand">
            <div style="display: flex">
              <div class="left">
                <p
                  style="font-size: 0.3rem; display: block; text-align: center"
                >
                  现在座位
                </p>
                <div
                  style="
                    display: flex;
                    width: 100%;
                    padding: 0.12rem;
                    justify-content: space-between;
                  "
                  v-for="(item, index) in submit_data"
                  :key="index"
                >
                  <p>姓名：{{ item.psgName }}</p>
                  <p>
                    座位：{{ item.obligateSeat ? item.obligateSeat : "无座位" }}
                  </p>
                </div>
              </div>
              <div class="right">
                <p
                  style="font-size: 0.3rem; display: block; text-align: center"
                >
                  需要座位
                </p>
                <div
                  style="
                    display: flex;
                    width: 100%;
                    padding: 0.12rem;
                    justify-content: space-between;
                  "
                  v-for="(item, index) in click_data"
                  :key="index"
                >
                  <p>姓名：{{ item.name }}</p>
                  <p style="color: red">座位：{{ item.zuowei }}</p>
                </div>
              </div>
            </div>
            <!-- 按钮 -->
            <el-button @click="zhengchang_licheng" type="success"
              >里程正常扣除</el-button
            >
            <!-- <el-button type="info">里程正常扣除（多人）</el-button> -->
            <!-- <el-button type="danger">订单确认</el-button> -->
            <!-- 按钮 -->
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="选座状态" prop="static"> </el-table-column>
      <el-table-column label="支付金额" prop="money"> </el-table-column>
      <el-table-column label="创建时间" prop="time"> </el-table-column>
    </el-table>

    <!-- 脱离文档流· -->
    <van-popup v-model="show">
      <input type="text" v-model="admin" placeholder="南航里程扣除账号" />
      <input type="text" v-model="pasword" placeholder="南航里程扣除密码" />
      <el-button @click="zhengchang_licheng" type="danger">订单确认</el-button>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1: true,
      tableData: [],
      //   展开的乘机人数据
      click_data: [],
      // 展开的全部数据
      oing: [],
      // 查询数据
      submit_data: [],
      show: false,

      // input提交、
      admin: "",
      pasword: "",
      // 航线下标
      index_mode: null,
    };
  },
  mounted() {
    let data = this.axios
      .get("https://wx.yaolvxing.com/submit/order")
      .then((res) => {
        this.tableData = res.data.data;
        console.log(res.data.data);
      });
      // 
      this.$axios.get("https://wx.yaolvxing.com/cx_price").then(res=>{
        // console.log(res.data.rsMessage)
        if(res.data.rsMessage == '自动选座'){
          this.value1 = false
        }
        if(res.data.rsMessage == '人工处理'){
          this.value1 = true
        }
      })
  },
  methods: {
    zhankai(e, m) {
      // console.log(e, m);
      if (m.length == 0) return false;

      this.oing = e;
      var load = this.$loading({
        lock: true,
        text: "加载中",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 需要座位
      let array = [];

      for (let i in e) {
        console.log(e);
        console.log(e[i]);
        if (e[i].name != undefined) {
          array.push({
            name: e[i].name,
            piaohao: e[i].piaohao,
            zuowei: e[i].zuowei,
            id: e[i].id,
          });
        } else {
          continue;
        }
      }

      this.click_data = array;
      let arr = [];
      this.axios
        .post(
          "https://wx.yaolvxing.com/selection/selection/query_airlines",
          "surname=" +
            this.click_data[0].name.substring(0, 1) +
            "&name=" +
            this.click_data[0].name.substring(1, 99) +
            "&ticket_number=" +
            this.click_data[0].piaohao
        )
        .then((res) => {
          if (res?.data?.data?.flightPeerList) {
            res.data.data.flightPeerList.forEach((item, index) => {
              if (item.fltNo == e.cz) {
                this.submit_data = item.passengers;
                this.index_mode = index;
                load.close();
              }
            });
          } else {
            load.close();
            this.$toast.fail(res.data.data.MESSAGE);
          }
        });
    },
    kaiqi_guanbi(){
      // 人工开启 true   人工关闭 false
      let mode = 0;
      if(this.value1 == false) mode = 1
      if(this.value1 == true) mode = 0
      // console.log(this.value1)
      this.$axios.get(
        'https://wx.yaolvxing.com/show/type_erer?type='+mode+''
      ).then(res=>{
        
      })
    },
    // 订单确认
    // queren() {
    //   if (this.show == false) {
    //     this.show = true;
    //   } else {
    //     // 订单确认提交
    //     var load = this.$loading({
    //       lock: true,
    //       text: "加载中",
    //       background: "rgba(0, 0, 0, 0.7)",
    //     });
    //     this.axios
    //       .post(
    //         "https://wx.yaolvxing.com/order/submit",
    //         "id=" +
    //           this.oing.id +
    //           "&name=" +
    //           this.admin +
    //           "&password=" +
    //           this.pasword
    //       )
    //       .then((res) => {
    //         load.close();
    //         this.show = false;
    //         this.$toast.success(res.data.rsMessage);
    //       });
    //   }
    // },
    // 里程正常扣除
    zhengchang_licheng() {
      if (this.show == false) {
        this.show = true;
      } else {
        this.axios
          .post(
            "https://wx.yaolvxing.com/order/mma",
            "surname=" +
              this.click_data[0].name.substring(0, 1) +
              "&name=" +
              this.click_data[0].name.substring(1, 99) +
              "&ticket_number=" +
              this.click_data[0].piaohao +
              "&mode_index=" +
              this.index_mode +
              "&id=" +
              this.oing.id +
              "&xia_admin=" +
              this.admin +
              "&xia_password=" +
              this.pasword
          )
          .then((res) => {
              
          });
      }
    },
  },
};
</script>


<style scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 0.9rem;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.left {
  width: 50vw;
  border-right: 1px solid #666;
  min-height: 2rem;
  max-height: auto;
}
.right {
  width: 50vw;
  max-height: auto;
  min-height: 2rem;
}
p {
  font-size: 0.3rem;
}
</style>